const additional = [
    {
        id: 1,
        heading: "External Examiner",
        paragraph:
            "Dr. Babasaheb Ambedkar Marathwada University, Aurangabad  for M.F.A.  Applied Art;  B.F.A.  (Bridge Course) Applied Art; B.F.A.  Applied Art.",
    },
    {
        id: 2,
        heading: "Examiner",
        paragraph:
            "Kavikulguru Kalidas Sanskrut Vishvavidyalay, Ramtek, Maharashtra, Nagpur- 441 106. for M.F.A.  Applied Art (First Semester) at College of Visual Arts, Indapur, Pune.",
    },
    {
        id: 3,
        heading: "Chairman (Theory), Paper Setter, Jury Member & Examiner  ",
        paragraph:
            "of Savitribai Phule Pune University for B.F.A.  Applied Art at Dr. D.Y.Patil College of Applied Art & Crafts, Akurdi, Pune.",
    },
];
export default additional;
