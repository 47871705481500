const teaching = [
    {
        id: 1,
        institute: "Pad. Dr. D.Y.Patil College of Applied Art & Craft, Pune",
        role: "Sr. Teaching Faculty",
        time: "from Sept. 2021 till Contd. AND from  2008 till 2015",
        practical:
            "Typography, Illustration, Branding & Communication Design, Advertising Campaign Design.",
        theory: "Theory of Visual Communication & History of Advertising.",
    },
    {
        id: 2,
        institute: "Adarash Chitrakala Mahavidyalay. Aurangabad",
        role: "Head  of the Department.",
        time: "from  2000 till 2008",
        practical:
            "2-D Design, 3-D Design, Still Life, Object Drawing, Human Anatomy.",
        theory: "History of Art",
    },
    {
        id: 3,
        institute: "Maharashtra Centre for Entrepreneurship",
        role: "Training Faculty (Marathwada  region of Maharashtra).",
        time: "from 2019 to 2020",
        practical:
            "Marketing Management, Product Promotion, Sales & Advertisement.",
        theory: "Marketing Management, Product Promotion, Sales & Advertisement.",
    },
    {
        id: 4,
        institute: "Sir J. J. Institute of Applied Art, Mumbai-01",
        role: "Online Visiting Faculty from B.F.A. 1st year.",
        time: "from April 2021 to July 2021.",
        practical: "Graphic Design",
        theory: "Graphic Design",
    },
    {
        id: 5,
        institute: "College of Visual Art, Indapur, Dist; Pune. ",
        role: "Visiting Faculty from B.F.A. 1st year to M.F.A. Final year.",
        time: "from 2020  till 2022",
        theory: "Advertising Art & Ideas",
    },
    {
        id: 6,
        institute: "Govt. College of Art & Design, Aurangabad.",
        role: "Visiting Faculty from B.F.A. 1st year to M.F.A. Final year.",
        time: "from 2016 till May 2020",
        practical: "Visual Communication Design, Illustration",
        theory: "Advertising Art & Ideas, Visual Communication, Dissertation.",
    },
    {
        id: 7,
        institute: "MGM's J.N.E. College of Architecture, Aurangabad.",
        role: "Visiting Faculty for a Semester",
        practical: "Basic Design & Visual Art.",
        time: "from 2016 till 2017",
    },
];

export default teaching;
