import React from "react";
import Card from "./TeachingSectionCard";

export default function Section({ list, heading }) {
    return (
        <div className="container mx-auto">
            <h3 className="text-3xl pt-10 pb-5 mx-4 text-yellow-300">{heading}</h3>
            <div className="grid grid-cols-1 gap-4 mx-4 lg:grid-cols-3">
                {list.map((e) => (
                    <Card
                        key={e.id}
                        institute={e.institute}
                        time={e.time}
                        role={e.role}
                        theory={e.theory}
                        practical={e.practical}
                    />
                ))}
            </div>
        </div>
    );
}
