const some = [
    {
        id: 1,
        paragraph:
            "Worked in a sub-committee of the 77th All India Marathi Sahitae Samenlan 2004, held in Aurangabad.",
    },
    {
        id: 2,
        paragraph:
            "Invited as a Judge & Guest for Art & Craft Exhibition 2008 by Little     Flower School, Cantonment, Aurangabad- 431002.",
    },
    {
        id: 3,
        paragraph:
            "Invited as a Judge for Techno Cultural 2013 by Pad. D.Y. Patil Institute of Master of Computer Applications, Akurdi, Pune.",
    },
    {
        id: 4,
        paragraph:
            "Invited for the Guest lecture on ‘Business Positioning’ by Gold Culture at Yuva Centre, Kharghar, Navi Mumbai in Dec-2015 organized by Buddha International Chamber of Commerce & Industry.",
    },
    {
        id: 5,
        paragraph:
            "Member, Students Assignment Evaluation Committee by Directorate of Art, Mumbai for Government School of Art, Aurangabad in March 2016.",
    },
    {
        id: 6,
        paragraph:
            "Guest lecture in a Workshop on Beautiful & Calligraphic writing 2017 in SBES College of Arts & Commerce, Aurangabad.",
    },
    {
        id: 7,
        paragraph:
            "Judging Committee Member, Fifth Interstate Kala Utsav 2018, organized by Yashwant Kala Mahavidyalay, Garkheda, Aurangabad.",
    },
    {
        id: 8,
        paragraph:
            "Member, Boards of Studies (2018 & 2020) for Fine Art to MIT-World Peace University, Kothrud, Pune. ",
    },
    {
        id: 9,
        paragraph:
            "Guest Lecture on 'Syllabus & Profession' & interaction session for Applied Art students at Amrita Vidyapeetham, Brahmathanam, Kochi (Kerla) dated 29th Jan.2020.",
    },
    {
        id: 10,
        paragraph:
            "Member, Convener Committee & Editorial Board  of International Webinar Conference of Fine Art-2021 scheduled on dated 27th February 2021 organized by Yashwant Kala Mahavidyalay, Aurangabad for the peer reviewed & UGC listed journal No. 40776 ‘Ajanta’ ISSN- 2277-5730 with Impact Factor- 6.399.",
    },
    {
        id: 11,
        paragraph:
            "Two Days National Level Workshop on 'Implementation of National Education Policy 2020' sponsored by Savitribai Phule Pune University organized by Dr. D. Y. Patil College of Pharmacy, Akurdi, Pune on 13th & 14th February 2023.",
    },
    {
        id: 12,
        paragraph:
            "Participated in the Webinar on 'Opportunities in Research Funding' organized by D. Y. Patil College of Engineering, Akurdi, Pune on  21st July 2023.",
    },
    {
        id: 13,
        paragraph:
            "Member, Boards of Studies (2023) for Applied Art to D.Y. Patil International University, Akurdi, Pune - 44.",
    },
    {
        id: 14,
        paragraph:
            "Participated in the 10 days Faculty Development Program titled 'Train the Trainer' and certified as Master Trainer in Teaching Learning Methodology organized by Dr. D. Y. Patil Educational Complex, Akurdi, Pune in association with ISTE and Accurate Gauging and instruments, Pune from 21st to 31st August 2023.",
    },
    {
        id: 15,
        paragraph:
            "Two Days National Level Workshop on ‘Brain Computer interfaces’ sponsored & organized by D. Y. Patil International University, Akurdi, Pune on 04th & 05th July 2024.",
    },
    {
        id: 16,
        paragraph:
            "Four Days State Level Faculty Development Program on ‘Outcome Based Education’ in collaboration with Savitribai Phule Pune University organized by Dr. D. Y. Patil College of Architecture, Akurdi, Pune on 20th to 24th August 2024.",
    },
    {
        id: 17,
        paragraph:
            "Three Days online Seminar on Eco Build: Bamboo Edition in collaboration with Konbac organized by Dr. D. Y. Patil College of Architecture, Akurdi, Pune from 25th to 27th September 2024.",
    },
];
export default some;
