import Section from "./components/Section";
import TeachingSection from "./components/TeachingSection";
import ContactDetails from "./components/ContactDetails";
// import ContactForm from "./components/ContactForm";
// import Gallery from "./components/Gallery";
import Intro from "./components/Intro";
import SlideShow from "./components/SlideShow";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import teaching from "./data/teaching";
import professional from "./data/professional";
import additional from "./data/additional";
import published from "./data/published";
import latest from "./data/latest";
import phd from "./data/phd";
import research from "./data/research";
import some from "./data/some";

function App() {
    return (
        <div className="bg-slate-800 text-slate-50">
            <NavBar />
            <Intro />
            <SlideShow />
            <h2 className="my-heading2" id="id-section-1">
                Experience
            </h2>
            <TeachingSection
                heading="Teaching experience in U.G. and P.G. Colleges"
                list={teaching}
            />
            <Section heading="Professional Experience" list={professional} />
            <Section
                heading="Additional Experience in Academics"
                list={additional}
            />
            <h2 className="my-heading2" id="id-section-2">
                Research
            </h2>
            <Section heading="Ph.D." list={phd} />
            <Section heading="Published Research Papers" list={published} />
            <Section heading="Latest Research Paper Status" list={latest} />
            <Section heading="Research Paper Presentation" list={research} />
            <Section heading="Some Academic Engagements" list={some} />
            {/* <Gallery /> */}
            <ContactDetails />
            {/* <ContactForm/> */}
            <Footer />
        </div>
    );
}

export default App;
