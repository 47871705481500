import React from "react";
import img from "../images/img.jpg";

export default function () {
    return (
        <div
            className="mx-auto flex flex-col-reverse md:flex-row mt-20"
            id="id-section-4"
        >
            <div className="p-10 md:p-20 md:w-1/2 border-t border-slate-500">
                <h2 className="text-5xl mb-5 text-rose-300">Contact</h2>
                {/*TODO: change action and method of this form */}
                <table className="text-slate-300">
                    <tr>
                        <td className="pe-4 text-slate-400">Email</td>
                        <td>weldoderahul@gmail.com</td>
                    </tr>
                    <tr>
                        <td className="pe-4 text-slate-400"> </td>
                        <td>weldoderahul@yahoo.com</td>
                    </tr>

                    <tr>
                        <td className="pe-4 text-slate-400">Phone</td>
                        <td>+91 9850409690</td>
                    </tr>
                </table>
            </div>
            <div className="md:w-1/2">
                <img
                    className="h-full w-full object-cover"
                    src={img}
                    alt=""
                />
            </div>
        </div>
    );
}
