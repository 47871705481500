const latest = [
    {
        id: 1,
        heading: "Title: 'The representation of sustainable energy sources with computing technology in the contemporary Art of Punjab.'",
        paragraph:
            "Paper accepted by Taylor & Francis Journal of Scopus Index - November 2024, as co-author.",
    },
    {
        id: 2,
        heading: "Title: 'Portrayal of Lonliness & sorrow by German Expressionist.'",
        paragraph:
            "Paper Accepted by UGC care listed Journal Shodh Kosh ISSN-2582-7472, Indore, Madhya Pradesh - June 2024, as co-author.",
    },
];
export default latest;
