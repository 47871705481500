const research = [
    {
        id: 1,
        heading: "Role of Colour in Promoting Sale",
        paragraph: "published in the proceeding  Journal presented at '7th National Conference of Fine Art' in Department of Fine Art of Dr. Babasaheb Ambedkar Marathwada University, Aurangabad on  25th & 26th February 2018 (Page No:07) with ISSN 2277-5730 Volume VIII.",
    },
    {
        id: 2,
        heading: "Study of Indian Advertising Norms: the legal Aspects",
        paragraph: "submitted & presented in the webinar is to be published in the proceeding E-Journal of Virtual International Conference on Multidisciplinary Research Trend by Nehru Yuva Kendra, Thiruvanthapuram, Kerala organized on 21st, 22nd,  & 23rd  November 2020.",
    },
    {
        id: 3,
        heading: "Best Advertising Media Newspaper",
        paragraph: "presented in the International Webinar Conference of Fine Art -2021 organized by Yashwant Kala Mahavidyalay, Aurangabad on 27th February 2021 to be published in peer reviewed & UGC care journal No. 40776 'Ajanta' ISSN- 2277-5730 with Impact Factor- 6.399.",
    }, 
];
export default research;
