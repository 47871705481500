// CHANGE NOTHING IN THIS CODE EXCEPT THE NAVBAR CONTNET
import React, { useContext } from "react";
import OffCanvasToggleContext from "../context/Context";
import youtube_icon from "../socialMediaIcons/youtube.png";
import facebook_icon from "../socialMediaIcons/facebook.png";
import linkedin_icon from "../socialMediaIcons/linkedin.png";

export default function OffCanvasMenu() {
    document.body.classList.add("overflow-x-hidden");
    const { isMenuOpen, toggleMenu } = useContext(OffCanvasToggleContext);

    return (
        <>
            {/* the navbar-group */}
            <div
                className={`absolute right-0 z-20 h-svh w-4/5 md:w-1/2 flex transition duration-500 ${
                    isMenuOpen ? "" : "translate-x-full"
                }`}
            >
                {/* close button */}
                <button
                    className="bg-zinc-50 text-zinc-950 p-2 w-fit h-fit rounded-md m-2 flex justify-center item-center"
                    onClick={toggleMenu}
                >
                    <span className="material-symbols-outlined">close</span>
                </button>
                {/* the navbar */}
                <div
                    className={`flex bg-slate-800 text-slate-50 overflow-auto w-full`}
                >
                    <div className="flex flex-col mt-10">
                        <a
                            href="#id-section-1"
                            className="font-semibold p-6"
                            onClick={toggleMenu}
                        >
                            Experience
                        </a>
                        <a
                            href="#id-section-2"
                            className="font-semibold p-6"
                            onClick={toggleMenu}
                        >
                            Research
                        </a>
                        <a
                            href="#id-section-3"
                            className="font-semibold p-6"
                            onClick={toggleMenu}
                        >
                            Gallery
                        </a>
                        <a
                            href="#id-section-4"
                            className="font-semibold p-6"
                            onClick={toggleMenu}
                        >
                            Contact
                        </a>

                        <div className="flex p-4">
                            <a href="https://www.youtube.com/@dr.rahulweldode3726">
                                <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                                    <img
                                        className="h-full w-full object-cover"
                                        src={youtube_icon}
                                    />
                                </div>
                            </a>
                            <a href="https://www.facebook.com/rahul.weldode">
                                <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                                    <img
                                        className="h-full w-full object-cover"
                                        src={facebook_icon}
                                    />
                                </div>
                            </a>
                            <a href="https://www.linkedin.com/in/dr-rahul-weldode-a27069230/">
                                <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                                    <img
                                        className="h-full w-full object-cover"
                                        src={linkedin_icon}
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* the cover */}
            <div
                className={`bg-slate-950 fixed z-10 w-screen h-svh transition  ${
                    isMenuOpen ? "opacity-25" : "hidden"
                }`}
                onClick={toggleMenu}
            ></div>
            {/* the button */}
            <button
                className={`md:hidden hover:bg-zinc-300 active:bg-zinc-500 active:text-zinc-50 transform duration-300 text-slate-50 font-semibold p-2 px-4 rounded-full flex justify-center items-center size-16 ${
                    isMenuOpen ? "opacity-0" : ""
                }`}
                onClick={toggleMenu}
            >
                <span className="material-symbols-outlined">menu</span>
            </button>
        </>
    );
}
