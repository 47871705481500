const published = [
    {
        id: 1,
        heading: "Mix Languages in Advance Advertisement: A Study",
        paragraph:
            "published in Dnyan Jagat, volume : 07, issue 01, Jan-March 2016, ISSN: 0976-8483 (In Marathi)",
    },
    {
        id: 2,
        heading: "Role of Typography and Illustration in Advertisement",
        paragraph:
            "Published in GALAXY LINK  volume- 04, Issue-02, of May-October 2016 on page 75, ISSN: 2319-8508",
    },
    {
        id: 3,
        heading: "Success of Amul's Advertising Series",
        paragraph:
            "Published  in Dynamic Research Society's, An International Interdisciplinary  Journal  volume- 01, Issue-02 of April-June 2016 on page 43, (P) ISSN: 2455-4979",
    },
    {
        id: 4,
        heading: "Phases of Alphabetical Development in Visual Art",
        paragraph:
            "is Published in Dynamic Research Society's, An International Interdisciplinary Journal volume- 01, Issue 03 of July-September 2016 on page 41, (P) ISSN: 2455-4979.",
    },
];
export default published;
