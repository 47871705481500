import React from "react";

export default function Card({ heading, paragraph }) {
    return (
        <div className="bg-slate-700 text-slate-50 rounded-lg p-4 ">
            <h3 className="text-xl font-bold">{heading}</h3>
            <p className="mt-2 text-sm">{paragraph}</p>
        </div>
    );
}

Card.defaultProps = {
    heading: "",
    paragraph: "",
};
