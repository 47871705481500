const professional = [
    {
        id: 1,
        heading: "Prelaunch Visual Artist & Litterateur",
        paragraph:
            "Copy writing for various Print / Relevant Media. (1992 till Contd.)",
    },
    {
        id: 2,
        heading: "Concept Designer",
        paragraph:
            "Director in Interlink Video Software, Aurangabad. (1994- 2001)",
    },
    {
        id: 3,
        heading: "Editor",
        paragraph:
            "Interschool quarterly magazine 'Magic Pen'. (1995-1996)",
    },
];
export default professional;
