import React from "react";
import {
    Navigation,
    Pagination,
    A11y,
    Scrollbar,
    EffectCreative,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
// images
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import img5 from "../images/img5.png";
import img6 from "../images/img6.png";
import img7 from "../images/img7.png";
import img8 from "../images/img8.png";
import img9 from "../images/img9.png";
import img10 from "../images/img10.png";
import img11 from "../images/img11.png";
import img12 from "../images/img12.png";
import img13 from "../images/img13.png";
import img14 from "../images/img14.png";
import img15 from "../images/img15.png";
import img16 from "../images/img16.png";
import img17 from "../images/img17.png";
import img18 from "../images/img18.png";
import img19 from "../images/img19.jpg";
import img20 from "../images/img20.jpg";
import img21 from "../images/img21.jpg";

export default function SlideShow() {
    const images = [
        {
            source: img1,
            caption:
                "Assignments of Illustration: Designed for M.F.A. (Applied Arts)",
        },
        {
            source: img2,
            caption: "Designed Billboard for M.F.A. (Applied Arts)",
        },
        {
            source: img3,
            caption: "Designed Poster for M.F.A. (Applied Arts)",
        },
        {
            source: img4,
            caption: "Designed Type Font for M.F.A. (Applied Arts)",
        },
        {
            source: img5,
            caption:
                "Same Type Font used in Campaign Designing (Application of Type Font)",
        },
        {
            source: img6,
            caption:
                "The same Assignment I published commercially for Office Display.",
        },
        {
            source: img7,
            caption: "Assignment of B.F.A. Applied Arts (Final year)",
        },
        {
            source: img8,
            caption: "Assignment of B.F.A. Applied Arts (First year)",
        },
        {
            source: img9,
            caption: "Professional Work (Illustrations & Page Layout)",
        },
        {
            source: img10,
            caption: "Professional Work (Publication)",
        },
        {
            source: img11,
            caption: "Professional Work (Publication)",
        },
        {
            source: img12,
            caption: "Professional Work (Mural)",
        },
        {
            source: img13,
            caption: "Professional Work (Painting) 4/6",
        },
        {
            source: img14,
            caption: "Professional Work (Painting) 2/4",
        },
        {
            source: img15,
            caption: "Professional Work (Literature)",
        },
        {
            source: img16,
            caption: "Designed few series on “Father” in Marathi",
        },
        {
            source: img17,
            caption:
                "Demo for the students of Adarash in Aurangabad while designing a series on “Father” in Marathi",
        },
        {
            source: img18,
            caption:
                "Landscape Demo in 2010 at Lonavala (1st years Outdoor of our Pad. Dr. D.Y. Patil College of Applied Arts & Crafts )",
        },
        {
            source: img19,
            caption: "Cutting-Pasting art work.",
        },
        {
            source: img20,
            caption: "Book Cover",
        },
        {
            source: img21,
            caption: "Book review article.",
        },
    ];
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
            effect={"creative"}
            creativeEffect={{
                prev: {
                    shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            }}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
        >
            {images.map((element) => {
                return (
                    <SwiperSlide key={element.source}>
                        <div className="relative h-[80vh] flex justify-center items-center">
                            <img
                                src={element.source}
                                className="w-auto h-full object-cover"
                                alt="Image description"
                            />
                            <div className="absolute bottom-5 left-0 right-0 text-center text-white bg-black bg-opacity-50 py-2">
                                <span className="text-lg">
                                    {element.caption}
                                </span>
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
