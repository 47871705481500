import React from "react";
import youtube_icon from "../socialMediaIcons/youtube.png";
import facebook_icon from "../socialMediaIcons/facebook.png";
import linkedin_icon from "../socialMediaIcons/linkedin.png";
/* NAVBAR CODE --- START 1/2*/
import { OffCanvasToggleProvider } from "../context/Context";
import OffCanvasMenu from "../components/OffCanvasMenu";
/* NAVBAR CODE --- END   1/2*/

export default function NavBar() {
    return (
        <>
            <div className="md:flex items-center justify-between hidden container mx-auto">
                <div className="flex">
                    <a href="https://www.youtube.com/@dr.rahulweldode3726">
                        <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                            <img className="h-full w-full object-cover" src={youtube_icon} />
                        </div>
                    </a>
                    <a href="https://www.facebook.com/rahul.weldode">
                        <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                            <img className="h-full w-full object-cover" src={facebook_icon} />
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/in/dr-rahul-weldode-a27069230/">
                        <div className="rounded-full p-3 transition duration-300 ease-in-out hover:bg-slate-700 flex items-center justify-center w-14">
                            <img className="h-full w-full object-cover" src={linkedin_icon} />
                        </div>
                    </a>
                </div>
                <div className="flex">
                    <a href="#id-section-1">
                        <div className="px-8 py-6 transition duration-300 ease-in-out hover:bg-slate-700">
                            Experience
                        </div>
                    </a>
                    <a href="#id-section-2">
                        <div className="px-8 py-6 transition duration-300 ease-in-out hover:bg-slate-700">
                            Research
                        </div>
                    </a>
                    <a href="#id-section-3">
                        <div className="px-8 py-6 transition duration-300 ease-in-out hover:bg-slate-700">
                            Gallery
                        </div>
                    </a>
                    <a href="#id-section-4">
                        <div className="px-8 py-6 transition duration-300 ease-in-out hover:bg-slate-700">
                            Contact
                        </div>
                    </a>
                </div>
            </div>
            {/* NAVBAR CODE --- START 2/2*/}
            <OffCanvasToggleProvider>
                <OffCanvasMenu />
            </OffCanvasToggleProvider>
            {/* NAVBAR CODE --- END   2/2*/}
        </>
    );
}
