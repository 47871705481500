const phd = [
    {
        id: 1,
        heading:
            "IMPORTANCE OF TYPOGRAPHY AND ILLUSTRATION IN ADVERTISEMENT : A STUDY",
        paragraph:
            "Awarded Ph.D. in Fine Art by Rashtrasant Tukdoji Maharaj Nagpur University on 08th October 2020",
    },
];
export default phd;
