import React from "react";

export default function Card({ institute, role, time, practical, theory }) {
    return (
        <div className="bg-slate-700 text-slate-50 rounded-lg p-4 ">
            <h3 className="text-2xl font-bold mb-4">{institute}</h3>
            <p className="mt-2 font-bold">{role}</p>
            <p className="text-sm">{time}</p>
            {practical ? (
                <p className="mt-2 font-bold">Practical subjects:</p>
            ) : (
                ""
            )}
            <p className="text-sm">{practical}</p>
            {theory ? (
                <p className="mt-2 font-bold">Theory subjects:</p>
            ) : (
                ""
            )}
            <p className="text-sm">{theory}</p>
        </div>
    );
}
