import React from "react";
import profilePic from "../images/profile.jpg";
import backgroundImage from "../images/bg.jpg";

export default function Intro() {
    return (
        <div
            className="bg-slate-700 lg:py-20"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover", // Ensures the image covers the whole div
                backgroundPosition: "center", // Centers the background image
            }}
        >
            <div className="container mx-auto flex flex-col items-center justify-center gap-14 p-8 text-center lg:text-left lg:flex-row bg-slate-400 bg-opacity-10 backdrop-blur-lg rounded-xl border border-white/20 shadow-lg">
                <img
                    src={profilePic}
                    alt="Flex Image"
                    className="max-w-64 h-64 object-cover rounded-full drop-shadow-2xl"
                />

                <div className="max-w-xl text-center">
                    <h1 className="text-6xl font-bold mb-3 text-blue-300 drop-shadow-2xl">
                        Dr. Rahul Weldode
                    </h1>
                    <p className="text-lg text-yellow-300 mb-2">
                        Faculty & Consultant in Applied (Visual) Art.
                    </p>
                    <p className="text-base">
                        B.F.A. (Applied Art); Dip. A .Ed; M.F.A. (Typography);
                        M.F.A. (Illustration); UGC-NET (Visual Art); Ph.D. in
                        Fine Art (Applied Art).
                    </p>
                </div>
            </div>
        </div>
    );
}
